import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Shorts() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { type: { eq: "shorts" }, draft: { ne: true } } }
        limit: 10
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            html
            id
            fields {
              slug
            }
            frontmatter {
              date
              type
              title
              description
              tags
            }
          }
        }
      }
    }
  `)
  return (
    <Layout cssClass="card">
      <SEO title="Short Stories"></SEO>
      {data.allMarkdownRemark.edges.map(({node}) => (
        <article className="blogPost">
          <h2>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </h2>
          {node.frontmatter.tags &&
            node.frontmatter.tags.map(tag => (
              <span className="tag"> {tag} </span>
            ))}

          <p>{node.frontmatter.description}</p>
        </article>
      ))}
    </Layout>
  )
}
